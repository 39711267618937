import React, { useState } from "react"
import BusinessClient, { IQRCode} from "../lib/BusinessClient"
import {  Button, Modal, Box, Menu, MenuItem, IconButton, Typography, Paper, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { WithId } from "mongodb"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { QRCode } from "react-qrcode-logo"

import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import CodeForm, { FormFields } from "../forms/CodeForm";

interface ActionDropdownProps {
    row: IQRCode; // Adjust this type to match the structure of your row data
}

const formatQRCode = (code: string) => {
    return code.match(/.{1,4}/g)?.join('-') ?? code
}


const Codes = () => {
    const qrRef = React.useRef<QRCode>(null);


    const [loaded, setLoaded] = React.useState<boolean>(false)
    const [qrcodes, setQRCodes] = React.useState<WithId<IQRCode>[]>([])

    const [adding, setAdding] = React.useState<boolean>(false)
    const [selectedCode, setSelectedCode] = React.useState<IQRCode>();
    const [open, setOpen] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [warn, setWarn] = React.useState<boolean>(false);


const ActionDropdown: React.FC<ActionDropdownProps> = ({ row}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = (action: string) => {
        console.log(`Action: ${action} on row:`, row);
        if(action === 'view') {
            setSelectedCode(row);
            setOpen(true);
        } else if(action === 'edit') {
            setSelectedCode(row);
            setEditing(true);
        } else if(action === 'disable') {
            setSelectedCode(row);
            setWarn(true);
        }

        handleClose();
    };


    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleAction('view')}>Zeige QR Code</MenuItem>
                <MenuItem onClick={() => handleAction('edit')}>Bearbeiten</MenuItem>
                <MenuItem onClick={() => handleAction('disable')}>Deaktivieren</MenuItem>
            </Menu>
        </>
    );
};


    React.useEffect(() => {
        const fetch = async () => {
            const qrcodes = await BusinessClient.instance.listQRCodes()
            //console.log(qrcodes)
            setQRCodes(qrcodes.reverse())
        }

        if(!loaded) {
            setLoaded(true);
            fetch();
        }
    }, [loaded])

    const generateQRHandler = async (data: FormFields) => {
        console.log('generate', data)
        await BusinessClient.instance.createQRCode(data)
        setAdding(false)
        setLoaded(false)
    }
    const updateQRHandler = async (data: FormFields) => {
        console.log('update', data)
        if(!selectedCode) return;
        await BusinessClient.instance.updateQRCode(selectedCode.code, data)
        setEditing(false)
        setLoaded(false)
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedCode(undefined);
    };

    const handleWarnClose = () => {
        setWarn(false);
        setSelectedCode(undefined);
    }

    const handleDeactivateCode = async () => {
        handleWarnClose();
        if(!selectedCode) return;
        await BusinessClient.instance.deactivateQRCode(selectedCode.code)
        setLoaded(false);
    }

    const download = () => {
        let fileName: string = selectedCode?.name ? `${selectedCode.name}.png` : 'qrcode.png';
        //fileName = `${account?.name}-${fileName}`;
        qrRef.current?.download('png', fileName);
    }


const columns: GridColDef[] = [
    { field: 'status', headerName: 'Status', width: 100, valueGetter: (params) => {
        const now = Date.now()
        if(params.row.expirationDate) {
            const expirationDate = new Date(params.row.expirationDate)
            if(expirationDate.getTime() < now) return 'inactive'
        }
        if(params.row.maxUsers > 0 && params.row.redeemCounter >= params.row.maxUsers) return 'inactive'
        return 'active'
    },
    renderCell: (params) => (
        <>
            {params.value === 'active' ? (
                <CheckCircleOutlineIcon style={{ color: 'green' }} titleAccess="valid"/>
            ) : (
                <CancelIcon style={{ color: 'red' }} titleAccess="expired or limit exceeded"/>
            )}

        </>
    )},
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'expirationDate', headerName: 'Ablaufdatum', width: 150, valueGetter: (params) => {
        return params.row.expirationDate ? params.row.expirationDate.split('T')[0] : 'never'
    }},
    { field: 'redeemCounter', headerName: 'Einlösungen', width: 120 },
    { field: 'maxUsers', headerName: 'Limit', width: 120, valueGetter: (params) => {
        return params.row.maxUsers === 0 ? 'unlimited' : params.row.maxUsers
    }},
    { field: 'licenseDurationDays', headerName: 'Lizenz', width: 120, valueGetter: (params) => {
        return params.row.licenseDurationDays === 0 ? '1 Jahr' : `${params.row.licenseDurationDays} Tage`
    }},
    {
        field: 'actions',
        headerName: 'Aktionen',
        width: 100,
        renderCell: (params) => <ActionDropdown row={params.row} />
    }
]



    return (
        <>
         {adding &&
            <Paper sx={{padding: 3, marginBottom: 3}}>

            <Typography component="h1" variant="h5" gutterBottom>
                Neuer Zugangscode
            </Typography>
            <Typography component="p" variant="body1" gutterBottom sx={{paddingBottom: 2}}>
                Erstelle einen neuen Zugangscode. Dieser ermöglicht den Zugriff auf ephoria.
            </Typography>
            <CodeForm didSubmit={generateQRHandler} didCancel={() => setAdding(false)}/>
            </Paper>
        }
        {editing &&
            <Paper sx={{padding: 3, marginBottom: 3}}>
                <Typography component="h1" variant="h5" gutterBottom>
                    Zugangscode bearbeiten
                </Typography>
                <Typography component="p" variant="body1" gutterBottom sx={{paddingBottom: 2}}>
                    Bestehenden Code bearbeiten.
                </Typography>
                <CodeForm defaults={selectedCode} didSubmit={updateQRHandler} didCancel={() => setEditing(false)} />
            </Paper>
        }


            <Paper sx={{padding: 3}}>

            <Typography component="h5" variant="h5" gutterBottom>
                Zugangscodes
            </Typography>

            {!adding &&
            <Button
                //variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => setAdding(!adding)}
                style={{padding: 10, borderRadius: 10, margin: 10}}>
                Code erstellen
            </Button>
            }

            <DataGrid
                rows={qrcodes}
                columns={columns}
                getRowId={(row) => row._id}
                rowSelection={false}
                disableRowSelectionOnClick={true}
            />
            </Paper>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {selectedCode && <>
                    <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
                        {selectedCode.name}
                    </Typography>

                    <QRCode
                        ref={qrRef}
                        value={`https://ephoria.health/get-ephoria/?qr=${selectedCode.code}`}
                        size={300}
                        logoImage={require("../assets/images/icon.png")}
                        logoHeight={40}
                        logoWidth={40}
                        ecLevel="H" // high error correction because we have a logo
                        //fgColor="green"
                        //eyeColor={"green"}
                        //logoPaddingStyle="circle"
                        //qrStyle="dots"
                    />
                    <div style={{ display: 'flex' }}>
                    <Typography variant="h6">{formatQRCode(selectedCode.code)}</Typography>
                    <Link onClick={download}>
                    <DownloadIcon  style={{ marginLeft: 50, marginTop: 3 }} />
                    </Link>
                    </div>
                    </>
                    }
                </Box>
            </Modal>

            <Dialog
        open={warn}
        onClose={handleWarnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Zugangscode "{selectedCode?.name}" deaktivieren?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Der Code wird umgehend deaktiviert, indem das Ablaufdatum auf heute gesetzt wird.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeactivateCode} color="error">Deaktivieren</Button>
          <Button onClick={handleWarnClose} autoFocus>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>

        </>
    )
}

export default Codes
