import React from "react"
import BusinessClient from "../lib/BusinessClient"
import { Paper, Typography } from "@mui/material"

const Numbers = () => {
    const [config, setConfig] = React.useState<any>({})

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BusinessClient.instance.config()
            console.log('result:', result)
            setConfig(result)
        }

        if(Object.keys(config).length === 0) fetch()
    })



    return (
        <Paper sx={{padding: 3}}>
           <Typography component="h5" variant="h5" gutterBottom>
                Individuelle Notfall-Nummern
            </Typography>

            { config?.emergencyNumbers && <>
            { config.emergencyNumbers.map((entry: any, index: number) => {
                return (
                    <Typography key={index}>
                        <strong>{entry.number}</strong>
                        <br/>
                        <br />
                        {Object.keys(entry.description).map((lang: string) => {
                            return (<>
                                <div key={lang}>
                                    <strong>{lang}: </strong>
                                    {entry.description[lang]}<br/>
                                    {entry.details[lang]}
                                </div>
                                <br/>
                                </>
                            )
                        })}

                    </Typography>

                )
            }) }
            </>
            }
            { !config?.emergencyNumbers &&
            <Typography>
                Keine kontospezifischen Notfallnummern konfiguriert.
            </Typography>
            }
        </Paper>
    )
}

export default Numbers;
