import React, { useState } from "react"

import BusinessClient, { IDashboardData } from "../lib/BusinessClient"


import { XAxis, YAxis,ResponsiveContainer, Bar, BarChart, Tooltip, CartesianGrid } from 'recharts';
import { Box, Card, LinearProgress, Paper, Typography } from "@mui/material";


const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-basiert
    return `${day}.${month}.`;
};

const Dashboard = () => {
    const [initialized, setInitialized] = useState<boolean>(false)
    const [data, setData] = useState<IDashboardData>()
    const [totalSignups, setTotalSignups] = useState<number>(0)

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BusinessClient.instance.dashboard()
            setData(result)

            // count sum of signups
            let sum = 0
            result.signups.forEach(signup => {
                sum += signup.signups
            })
            setTotalSignups(sum);

            setInitialized(true);

        }

        if(!initialized) fetch()
    })

    return (
        <Paper sx={{padding: 3}}>

            <Typography component="h5" variant="h5" gutterBottom>
                Dashboard
            </Typography>

            <Card sx={{padding: 3, marginBottom: 3, display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Benutzer
                    </Typography>
                    <Typography variant="h2" gutterBottom>
                        {data?.totalUsers ?? '-'}
                    </Typography>
                </Box>
                <Box sx={{paddingRight: 10}}>
                    <Typography variant="h6" gutterBottom>
                        API Credits
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {data?.credits ?? '-'}
                    </Typography>
                </Box>
            </Card>



            <Card sx={{padding: 3, marginBottom: 3}}>
                <Typography variant="h6" gutterBottom>
                    Interaktionen in den letzten 30 Tagen
                </Typography>
                <Typography variant="body1" gutterBottom>
                Beispiele für qualifizierte Interaktionen: Tagebucheintrag, Gespräch geführt, Fragebogen ausgefüllt etc.
                </Typography>
                {data &&
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data?.usage}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={formatDate} />
                        <YAxis />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="interactions" fill="#298044" />
                    </BarChart>
                </ResponsiveContainer>
                }
                {!data && <LinearProgress style={{marginTop: 50, alignSelf: 'center'}} />}
            </Card>



            <Card sx={{padding: 3, marginBottom: 3}}>
                <Typography variant="h6" gutterBottom>
                    {totalSignups} neue Benutzer in den letzten 30 Tagen
                </Typography>
                {data &&
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data?.signups}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={formatDate} />
                        <YAxis />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="signups" fill="#bf5a0d" />
                    </BarChart>
                </ResponsiveContainer>
                }
                {!data && <LinearProgress style={{marginTop: 50, alignSelf: 'center'}} />}
            </Card>




        </Paper>
    )
}

export default Dashboard
