
import Dashboard from "../pages/Dashboard";
import Codes from "../pages/Codes";
import Settings from "../pages/Settings";
import Admins from "../pages/Admins";
import Numbers from "../pages/Numbers";
import Insights from "../pages/Insights";

// https://mui.com/material-ui/material-icons/
import SettingsIcon from '@mui/icons-material/Settings';
import QrCodeIcon from '@mui/icons-material/QrCode';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PeopleIcon from '@mui/icons-material/People';
import InsightsIcon from '@mui/icons-material/Insights';

import { RouteType } from "./config";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <Dashboard />,
    state: "home"
  },
  {
    path: "/",
    element: <Dashboard />,
    state: "home",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <MultilineChartIcon />
    }
  },
  {
    path: "/insights",
    element: <Insights />,
    state: "insights",
    sidebarProps: {
      displayText: "Insights",
      icon: <InsightsIcon />
    }
  },
  {
    path: "/codes",
    element: <Codes />,
    state: "accounts",
    sidebarProps: {
      displayText: "Zugangscodes",
      icon: <QrCodeIcon />
    }
  },
  {
    path: "/numbers",
    element: <Numbers />,
    state: "numbers",
    sidebarProps: {
      displayText: "Notfall-Nummern",
      icon: <LocalPhoneIcon />
    }
  },
  {
    path: "/admins",
    element: <Admins />,
    state: "admins",
    sidebarProps: {
      displayText: "Administratoren",
      icon: <PeopleIcon />
    }
  },
  {
    path: "/settings",
    element: <Settings />,
    state: "settings",
    sidebarProps: {
      displayText: "Einstellungen",
      icon: <SettingsIcon />
    }
  },

]

export default appRoutes