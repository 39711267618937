import { Paper, Typography } from "@mui/material"

const Settings = () => {


    return (
        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5" gutterBottom>
                Einstellungen
            </Typography>

            <Typography component="body" gutterBottom>
                Dieses Feature ist in Arbeit.
            </Typography>
        </Paper>
    )
}

export default Settings
